import Axios from 'axios'
import humps from 'humps'

const decamelizeThatDontBreaksFile = object => {
  if (object && !(object instanceof File)) {
    if (object instanceof Array) {
      return object.map(item => decamelizeThatDontBreaksFile(item))
    }
    if (object instanceof FormData) {
      const formData = new FormData()
      /* eslint-disable */
      for (const [key, value] of object.entries()) {
        formData.append(humps.decamelize(key), value)
      }
      /* eslint-enable */
      return formData
    }
    if (typeof object === 'object') {
      return Object.keys(object).reduce(
        (acc, next) => ({
          ...acc,
          [humps.decamelize(next)]: decamelizeThatDontBreaksFile(object[next]),
        }),
        {}
      )
    }
  }
  return object
}

const api = Axios.create({
  baseURL: process.env.GATSBY_BACKEND_URL,
  transformResponse: [...Axios.defaults.transformResponse, data => humps.camelizeKeys(data)],
  transformRequest: [data => decamelizeThatDontBreaksFile(data), ...Axios.defaults.transformRequest],
})

api.interceptors.request.use(config => {
  const newConfig = { ...config }
  if (config.params) {
    newConfig.params = Object.keys(config.params).reduce(
      (acc, key) => ({ ...acc, [humps.decamelize(key)]: config.params[key] }),
      {}
    )
  }
  return newConfig
})

api.defaults.withCredentials = true

export default api