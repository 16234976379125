const { getCookie, authenticateWithToken, isRefreshTokenExpired, removeCookie, isTokenExpired, newRefreshToken, domainName } = require('./src/data/tokenManager')

exports.onClientEntry = () => {
  const token = getCookie('token')

  if (token) {
    if (isTokenExpired(token)) {
      if (isRefreshTokenExpired(token))
        removeCookie('token', domainName)
      else
        newRefreshToken(token)
    }
    else
      authenticateWithToken(token)
  }
}