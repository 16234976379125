import API from './axios'
import moment from 'moment'

const toStorableToken = token => {
  const { accessToken, refreshToken } = token
  return {
    accessToken,
    refreshToken,
    expiresAt: moment().add(token.expiresIn || 900, 'seconds'),
    refreshExpireAt: moment().add(token.refreshExpiresIn || 86400, 'seconds'),
  }
}

export const getLocalStorageToken = () => JSON.parse(typeof window !== 'undefined' && window.localStorage.getItem('token'))
export const isTokenExpired = token => moment().diff(token.expiresAt, 'seconds') > -60
export const isRefreshTokenExpired = token => moment().diff(token.refreshExpireAt, 'seconds') > -60
export const isLoggedIn = () => getLocalStorageToken() !== null
export const storeToken = token => typeof window !== 'undefined' && window.localStorage.setItem('token', JSON.stringify(token))
export const authenticateWithToken = token => storeToken(token)
export const removeToken = () => typeof window !== 'undefined' && window.localStorage.removeItem('token')
export const domainName = process.env.GATSBY_COOKIE_DOMAIN


export function removeCookie(cname, domain) {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`
}

export function logout() {
  removeCookie('token', domainName)
  removeToken()
}

export const handleLogout = (setIsConnected) => {
  logout()
  setIsConnected(false)
}

export const getRefreshToken = async refreshToken => {
  try {
    const res = await API.post('/auth/refresh-token', {
      refreshToken,
    })
    const token = toStorableToken(res.data)
    storeToken(token)
    return token
  } catch (e) {
    if (e.response.status === 401) removeCookie('token')
  }
}
export const newRefreshToken = async (token) => {
  token = await getRefreshToken(token.refreshToken)
  authenticateWithToken(token)
  updateCookie(token)
}

export const getCookie = (cname) => {
  try {
    const value = document.cookie.split(`${cname}=`)[1].split(';')[0]
    const parseValue = JSON.parse(value)
    return parseValue
  }
  catch (err) {
    return undefined
  }
}

export const updateCookie = (token, domain) => {
  document.cookie = `token=${JSON.stringify(token)}; domain=${domain}`;
}
